<template>
  <div v-cloak class="view-wrap">
    <div class="view-content">
      <!-- sale only : tron -->
      <section id="hero" class="bg-regular" :class="{'tron': sale.isLive && sale.name === 'cyber'}">
        <div class="section-inner">
          <div class="container">
            <div class="content">
              <div class="copy">
                <h1>A more secure way to browse the&nbsp;web</h1>
                <p>TunnelBear encrypts your internet connection to keep your online activity private on any network.</p>
                <div class="btn-holder">
                  <!-- sale only -->
                  <div v-if="sale.isLive && sale.couponRoute">
                    <router-link
                      :to="sale.couponRoute"
                      class="btn btn-lg"
                      :class="{'btn-decor': sale.isLive && sale.name === 'fall-sale' }">
                      Winter Sale! Get<span class="hidden-xs"></span> 67% off</router-link>
                    <p class="fine-print-lg mtop-10 bold">
                      Your first year of TunnelBear for <span class="strike" aria-hidden="true">$119USD</span> <strong>$39.99USD</strong>
                    </p>
                  </div>
                  <!-- end -->
                  <router-link v-else
                    :to="{name: 'pricing'}"
                    class="btn btn-lg">
                    Get TunnelBear now</router-link>
                </div>
              </div>
              <div class="img-holder" :class="{'position': !sale.isLive}">
                <div class="bear">
                 <!-- sale only -->
                  <template v-if="sale.isLive && sale.name === 'winter-sale'">
                    <img class="img" src="./images/winter-sale-bear.svg" alt="winter-sale" />
                  </template>
                  <!-- end -->
                  <template v-else>
                    <img v-if="is.mobile() || is.ie()" class="img offset-position" src="./images/hero-bear.svg" alt="">
                    <video v-else autoplay muted>
                      <source v-if="highRes" src="./videos/hero-animation@2x.mp4" type="video/mp4">
                      <source v-else src="./videos/hero-animation.mp4" type="video/mp4">
                      <img class="img offset-position" src="./images/hero-bear.svg" alt="">
                    </video>
                  </template>
                </div>
                <template v-if="is.mobile() && !sale.isLive">
                  <img-set v-if="is.mac() || is.ios()"
                    classes="device ios"
                    :webpSrc="require('@/views/home/images/devices/iphone.webp')"
                    :webpSrc2x="require('@/views/home/images/devices/iphone@2x.webp')"
                    :imgSrc="require('@/views/home/images/devices/iphone.png')"
                    :imgSrc2x="require('@/views/home/images/devices/iphone@2x.png')"/>
                  <img-set v-else
                    classes="device android"
                    :webpSrc="require('@/views/home/images/devices/android.webp')"
                    :webpSrc2x="require('@/views/home/images/devices/android@2x.webp')"
                    :imgSrc="require('@/views/home/images/devices/android.png')"
                    :imgSrc2x="require('@/views/home/images/devices/android@2x.png')"/>
                </template>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="recommended" class="bg-light">
        <div class="section-inner">
          <div class="element large centered content">
            <img src="./images/logos/forbes.svg" alt="Forbes">
            <img src="./images/logos/verge.svg" alt="the Verge">
            <img srcset="./images/logos/lifehacker.png 1x, ./images/logos/lifehacker@2x.png 2x" src="./images/logos/lifehacker.png" alt="LifeHacker">
            <img src="./images/logos/tnw.svg" alt="TNW">
            <img src="./images/logos/vb.svg" alt="VB">
            <img src="./images/logos/techradar.svg" alt="TechRadar">
          </div>
        </div>
      </section>
      <app-wrap sectionClass="bg-alt">
        <div class="copy">
          <h2>Easy-to-use apps for all your devices</h2>
          <p>Just open the TunnelBear app, select a country, and flip the switch. Once you're connected, TunnelBear will work quietly in the background to keep your data secure.</p>
          <div class="hidden-xs">
            <div class="app-list">
              <div class="app">
                <img src="./images/apps/mac.svg" alt="">
                <p class="fine-print-lg">Mac</p>
              </div>
              <div class="app">
                <img src="./images/apps/windows.svg" alt="">
                <p class="fine-print-lg">Windows</p>
              </div>
              <div class="app">
                <img src="./images/apps/ios.svg" alt="">
                <p class="fine-print-lg">iOS</p>
              </div>
              <div class="app">
                <img src="./images/apps/android.svg" alt="">
                <p class="fine-print-lg">Android</p>
              </div>
            </div>
            <router-link :to="{name: 'pricing'}" class="btn btn-lg">Get TunnelBear now</router-link>
          </div>
        </div>
      </app-wrap>
      <section id="content-list" class="bg-light tb-opt-1">
        <div class="section-inner">
          <div class="container">
            <h2 class="text-center">Why millions of people are using TunnelBear</h2>
            <div class="content">
              <div class="copy">
                <div class="img-text">
                  <img-set
                    lazyload
                    classes="mobile-btn app-store"
                    :imgSrc="require('@/views/home/images/feature-wifi.svg')"/>
                  <div>
                    <h3 class="h6">Stop password and data&nbsp;theft</h3>
                    <p>Hackers can steal passwords and data over insecure public WiFi. TunnelBear blocks them to keep you&nbsp;secure.</p>
                  </div>
                </div>
                <div class="img-text">
                  <img-set
                    lazyload
                    :webpSrc="require('./images/feature-everywhere.webp')"
                    :webpSrc2x="require('./images/feature-everywhere@2x.webp')"
                    :imgSrc="require('./images/feature-everywhere.png')"
                    :imgSrc2x="require('./images/feature-everywhere@2x.png')"/>
                  <div>
                    <h3 class="h6">Access global&nbsp;content</h3>
                    <p>Some content is only available in certain regions. TunnelBear changes your virtual location so you can see it&nbsp;anywhere.</p>
                  </div>
                </div>
                <div class="img-text">
                  <img-set
                    lazyload
                    :imgSrc="require('@/views/home/images/feature-bypass.svg')"/>
                  <div>
                    <h3 class="h6">Bypass local&nbsp;censorship</h3>
                    <p>Some governments block popular websites and apps. TunnelBear unblocks them by changing your virtual&nbsp;location.</p>
                  </div>
                </div>
              </div>
              <div class="copy">
                <div class="img-text">
                  <img-set
                    lazyload
                    :imgSrc="require('@/views/home/images/feature-surveillance.svg')"/>
                  <div>
                    <h3 class="h6">Protect your online&nbsp;privacy</h3>
                    <p>Network owners and internet providers can see everything you do online. With TunnelBear on, they can't see a&nbsp;thing.</p>
                  </div>
                </div>
                <div class="img-text">
                  <img-set
                    lazyload
                    :imgSrc="require('@/views/home/images/feature-private.svg')"/>
                  <div>
                    <h3 class="h6">Prevent IP-based tracking</h3>
                    <p>Ad services use your IP address to track your behaviour across sites. TunnelBear stops them by assigning you a new&nbsp;IP.</p>
                  </div>
                </div>
                <div class="img-text">
                  <img-set
                    lazyload
                    :imgSrc="require('@/views/home/images/feature-more.svg')"/>
                  <div>
                    <h3 class="h6">And way, way&nbsp;more</h3>
                    <p>Play on new game servers, prevent speed throttling, and unblock apps and websites on school and work networks.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <country-access bg="bg-space"></country-access>
      <section id="private" class="bg-light">
        <div class="section-inner">
          <div class="container">
            <h2 class="text-center">Everything a VPN should have (and more)</h2>
            <div class="content">
              <div class="img-holder">
                <img-set
                    lazyload
                    :imgSrc="require('@/views/home/images/protect-bear.svg')"/>
              </div>
              <div class="copy">
                <div class="img-text">
                  <img-set
                    lazyload
                    :imgSrc="require('@/views/home/images/feature-speed.svg')"/>
                  <div>
                    <h3 class="h6">Engineered for speed</h3>
                    <p>Our global server network is optimized to let you surf and stream quickly. No throttling, no buffering, no&nbsp;fuss.</p>
                  </div>
                </div>
                <div class="img-text">
                  <img-set
                    lazyload
                    :imgSrc="require('@/views/home/images/feature-audit.svg')"/>
                  <div>
                    <h3 class="h6">Security you can rely on</h3>
                    <p>TunnelBear is the only VPN in the world to publish regular, independent security audits of our&nbsp;apps.</p>
                  </div>
                </div>
                <div class="img-text">
                  <img-set
                    lazyload
                    :imgSrc="require('@/views/home/images/feature-strong.svg')"/>
                  <div>
                    <h3 class="h6">Strong Encryption</h3>
                    <p>TunnelBear uses strong AES 256-bit encryption by default. Weaker encryption isn't even an option.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="audit" class="bg-regular">
        <div class="section-inner">
          <div class="container">
            <div class="content">
              <div class="copy text-center">
                <div class="copy-inner">
                  <h2>Why millions of people trust TunnelBear</h2>
                  <p>TunnelBear respects your privacy. We will never monitor, log, or sell any of your browsing activity. As the only VPN in the industry to perform annual, independent&nbsp;<a :href="this.external.audit">security&nbsp;audits</a>, you can trust us to keep your connection&nbsp;secure.</p>
                </div>
              </div>
              <div class="img-holder">
                <img-set
                  lazyload
                  :imgSrc="require('@/views/home/images/audit-bear.svg')"
                  alt="Independently Audited Code"/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="FAQ" class="bg-light">
        <div class="section-inner">
          <div class="container">
            <div class="content">
              <h2 class="text-center">Frequently Asked Questions</h2>
              <ul class="faq">
                <li>
                  <list-item>
                    <template slot="title">What is TunnelBear and how does&nbsp;it&nbsp;work?</template>
                    <template slot="content">
                      <p>TunnelBear is a virtual private network (or VPN for short).</p>
                      <p>When you turn it on, all your device's incoming and outgoing data gets routed through a secure server which protects it with Grizzly-grade encryption.</p>
                      <p>That means when an internet service provider, network owner, or even hacker tries to snoop on your browsing activity, all they'll see is garbled, unreadable junk.</p>
                      <p>This process also changes your IP address (it's kind of like your internet phone number) to stop anyone online from seeing your real physical location or tying your activity back to you directly. </p>
                    </template>
                  </list-item>
                </li>
                <li>
                  <list-item>
                    <template slot="title">Is my online privacy worth the&nbsp;cost?</template>
                    <template slot="content">
                      <p>The value of online privacy depends on how comfortable you are with companies knowing <a href="https://news.vice.com/en_ca/article/j5dkmd/how-much-is-is-your-personal-data-really-worth" target="_blank">intimate details about your life</a>. Often, they buy that information directly from your internet service provider (ISP), who can legally <a href="https://www.washingtonpost.com/news/the-switch/wp/2017/03/29/what-to-expect-now-that-internet-providers-can-collect-and-sell-your-web-browser-history/?noredirect=on&utm_term=.3c623097509a" target="_blank">package and sell details about everything you do online</a>.</p>
                      <p>The really troubling part is that once that information has been packaged and sold, it will most likely get sold again, moving from database to database for different purposes. Eventually, it could even end up being <a href="https://www.forbes.com/sites/thomasbrewster/2017/03/30/fcc-privacy-rules-how-isps-will-actually-sell-your-data/#92c0f7421d17" target="_blank">leaked into the ad-tech ecosystem</a> where there's very little oversight for how your information is used, stored or protected.</p>
                      <p>Ultimately, privacy is a personal thing. Some people care a lot, while others don't care at all. For those that do, TunnelBear is a simple, affordable way to stop more of your data from being collected and sold in the future.</p>
                    </template>
                  </list-item>
                </li>
                <li>
                  <list-item>
                    <template slot="title">How can I confirm TunnelBear is keeping me&nbsp;secure?</template>
                    <template slot="content">
                      <p>After connecting to a TunnelBear server (by selecting a country and flipping the switch in the app), visit <a href="https://bearsmyip.com/" target="_blank">bearsmyip.com</a> to confirm that you appear in the country you selected. If it's a match, you're secure! As long as you don't disconnect from TunnelBear, all of your online activity will be encrypted and appear as though it's coming from a computer in the country you selected.</p>
                    </template>
                  </list-item>
                </li>
                <li>
                  <list-item>
                    <template slot="title">Will TunnelBear slow or change my browsing&nbsp;experience?</template>
                    <template slot="content">
                      <p>TunnelBear shouldn't change your browsing experience unless you're tunnelling to a country that's really far away. For example, if you connect to a country halfway around the world, your browsing has to travel long distances before it reaches you. That can make pages load slower than usual, so unless you need to access something specific, select Fastest in TunnelBear's country selector to connect to the fastest available server.</p>
                      <p>If you're regularly slowed down by your internet service provider when watching videos or playing games, TunnelBear can actually speed up your connection by not allowing them to see what you're doing (and slow you down if they don't like it).</p>
                    </template>
                  </list-item>
                </li>
                <li>
                  <list-item>
                    <template slot="title">How many devices can I use on one TunnelBear&nbsp;account?</template>
                    <template slot="content">
                      <p>There is no limit on the number of devices you can use with TunnelBear. Simply visit <router-link :to="{name: 'download'}">tunnelbear.com/download</router-link> on each device to install the TunnelBear app. Once the app is installed on your new device, log in with your existing TunnelBear account and connect to a server.</p>
                    </template>
                  </list-item>
                </li>
                <li>
                  <list-item>
                    <template slot="title">Why should I use TunnelBear over other&nbsp;VPNs?</template>
                    <template slot="content">
                      <ul>
                        <li><p>We are the first consumer VPN to perform a third party security audit and <a :href="ENV.blogURL + '/tunnelbear_public_security_audit/'" target="_Blank">publish the results</a>, something we are committed to doing <a :href="this.external.audit" target="_Blank"> every year</a>.</p></li>
                        <li><p>We let you review and delete your own account data <a href="https://help.tunnelbear.com/hc/en-us/articles/360006798512" target="_Blank">directly from our website</a> (most other VPNs don't).</p></li>
                        <li><p>Unlike other providers, you don't need to be a lawyer to understand our <router-link :to="{name: 'privacy-policy'}">Privacy Policy</router-link>.</p></li>
                        <li><p>We've received over 175,000 combined 5-star ratings on different app stores.</p></li>
                        <li><p>TunnelBear apps are really easy to use—one on/off switch, no tech stuff.</p></li>
                        <li><p>We let you <router-link :to="{ name: 'pricing' }">try before you buy</router-link> with 2GB of free monthly data.</p></li>
                        <li><p>Bears are super adorable.</p></li>
                      </ul>
                    </template>
                  </list-item>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section id="download" class="bg-alt">
        <div class="section-inner">
          <div class="container text-center">
            <div class="element copy centered">
              <h2>Download TunnelBear to start browsing privately&nbsp;today!</h2>
              <a v-if="is.ios()" :href="external.downloadLinks.ios">
                <img-set
                  lazyload
                  classes="mobile-btn app-store"
                  :imgSrc="require('@/images/app-store.svg')"
                  alt="Download on the App Store"/>
              </a>
              <router-link v-else class="btn btn-lg" :to="{name: 'pricing'}">Get TunnelBear now</router-link>
            </div>
          </div>
        </div>
      </section>
      <ai-chat-bot v-if='!isAiBlockedCountry'/>
    </div>
  </div>
</template>

<script>
import appWrap from '@/components/app-wrap/app-wrap'
import countryAccess from '@/components/country-access/country-access'
import audit from '@/components/audit/audit'
import listItem from '@/components/list-item/list-item'
import is from 'is_js'
import aiChatBot from '@/components/ai-chat-bot/ai-chat-bot.vue'

export default {
  name: 'home',
  components: {
    'app-wrap': appWrap,
    'audit-section': audit,
    'list-item': listItem,
    'country-access': countryAccess,
    'ai-chat-bot': aiChatBot
  },
  data () {
    return {
      is,
      isAiBlockedCountry: false
    }
  },
  created: async function () {
    this.$store.state.pricingService.getPricingData()
    this.isAiBlockedCountry = await this.$store.state.locationService.isAiBlockedCountry()
  },
  computed: {
    sale: function () {
      return this.$store.state.pricingService.sale
    },
    mobileDownloadLink: function () {
      return this.is.ios() ? this.external.downloadLinks.ios : this.external.downloadLinks.android
    },
    store: function () {
      return this.is.ios() ? 'App Store' : 'Google Play Store'
    },
    highRes: function () {
      return window && window.devicePixelRatio > 1
    }
  },
  beforeMount: function () {
    this.$emit('modifyLayout', {
      bgColor: (this.sale.isLive && this.sale.name === 'cyber') ? 'bg-black' : 'bg-regular'
    })
  }
}
</script>

<style scoped lang="stylus" src="./home.styl" >
