import axios from 'axios'

class LocationService {
  constructor () {
    this.ENV = process.env.CONFIG
    this.countryIso = null // null: not started, otherwise a promise that will resolve to the 2-letter country code
    this.sanctionedCountries = [ // we offer bandwidth since we can't sell there any more
      'RU', // russia
      'BY', // belarus
      'CU', // Cuba
      'IR', // Iran
      'SD' // Sudan
    ]
    this.aiBlockedCountries = [
      'UA', // Ukraine
      'SY', // Syria
      'KP', // North Korea
      'RU', // Russia
      'BY' // belarus
    ]
    this.requireAgeCheckCountries = [
      'KR' // South Korea
    ]
  }

  async isSanctioned () {
    const iso = await this.getCountryCode()
    return this.sanctionedCountries.includes(iso)
  }

  async isAiBlockedCountry () {
    const iso = await this.getCountryCode()
    return this.aiBlockedCountries.includes(iso)
  }

  async isRequireAgeCheckCountry () {
    const iso = await this.getCountryCode()
    return this.requireAgeCheckCountries.includes(iso)
  }

  // use getCountryCode to get the user's country.
  getCountryCode () {
    if (this.countryIso === null) {
      this.countryIso = this.loadCountry()
    }
    return this.countryIso
  }

  // private method to class.Should use getCountryCode to get the user's country.
  loadCountry () {
    let request = axios.get(this.ENV.coreBaseUrl + '/bearsmyip/location')

    if (this.ENV.name === 'development') {
      const DEV_COUNTRY = 'CA' // change to test geo restricted behaviours locally
      request = request.catch(e => null).then(() => {
        console.warn(`Ignoring location request for local dev and using ${DEV_COUNTRY}`)
        return { data: { 'countryCode': DEV_COUNTRY, 'countryName': 'Canada', 'region': 'Ontario', 'city': 'Toronto (North York)', 'postalCode': 'M2K', 'latitude': 43.78, 'longitude': -79.4133 } }
      })
    } else if (this.ENV.name === 'staging') {
      const STAGING_COUNTRY_MAP = {
        'NO': 'IN', // test india page by connecting via norway on staging
        'CO': 'BY' // test belarus sanction page via colombia
      }
      request = request.then(response => {
        if (response.data.countryCode in STAGING_COUNTRY_MAP) {
          response.data.countryCode = STAGING_COUNTRY_MAP[response.data.countryCode]
        }
        return response
      })
    }

    return request.then(
      response => response.data.countryCode,
      requestError => {
        this.countryIso = null // clear out rejected promise so next getCountryCode will retry
        console.log('Failed to load location', requestError)
        return Promise.reject(new Error('Failed to load location'))
      })
  }
}

export { LocationService }
