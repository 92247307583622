<template>
  <div id="hello-bar" class="bg-light">
    <div class="hello-bar-inner">
      <div class="content content-left">
        <p class="hidden-xs"><strong>{{sale.tagline}}</strong></p>
        <p class="visible-xs"><strong>{{sale.tagline}}</strong></p>
      </div>
      <div v-if="sale.countdown.show" class="content content-right">
        <div v-if="sale.name === 'holiday'" class="lights" :class="{'off': !lightsOn}"></div>
        <p class="bold hidden-xs">Offer ends in</p>
        <countdown-timer :targetDate="sale.countdown.endDate"></countdown-timer>
      </div>
      <div v-if="!hideCtaButton" class="btn-wrap">
        <router-link :to="sale.couponSale ? sale.bannerRoute : {name: 'checkout'}"
          class="btn"
          data-cy="btn--hello-cta">
          {{sale.titleButton}}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import countdownTimer from '@/components/countdown-timer/countdown-timer'
export default {
  name: 'hello-bar',
  props: {
    hideCtaButton: Boolean
  },
  components: {
    'countdown-timer': countdownTimer
  },
  data () {
    return {
      lightsOn: true,
      flickerCount: 0,
      lightsLoop: null
    }
  },
  mounted: function () {
    this.flickerLights()
  },
  computed: {
    sale: function () {
      return this.$store.state.pricingService.sale
    }
  },
  methods: {
    flickerLights: function () {
      if (this.flickerCount > 7) return
      this.lightsLoop = setTimeout(() => {
        this.lightsOn = !this.lightsOn
        this.flickerCount++
        this.flickerLights()
      }, 1000)
    }
  },
  beforeDestroy: function () {
    clearTimeout(this.lightsLoop)
  }
}
</script>

<style scoped lang="stylus" src="./hello-bar.styl" >
